<template>
  <v-app id="app">
    <v-app-bar
      app
      class="header"
      :style="scroll > 200 ? 'background: #FFF !important;' : ''"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Logo"
          class="pd-l"
          contain
          :src="require('./assets/logo.svg')"
        />
      </div>

      <v-spacer></v-spacer>
      <div v-if="isMobile">
        <v-icon
          id="menu-icon"
          class="menu-icon mt-2 mr-2"
          @click.stop.prevent="openMenu()"
        >
          mdi-menu
        </v-icon>
      </div>
      <div v-else class="pd-r mt-5">
        <span
          id="menu-home"
          class="menu mr-5"
          @click.stop.prevent="menu = 'home'"
        >
          {{ $t("menu.home") }}
        </span>
        <span
          id="menu-about"
          class="menu mr-5"
          @click.stop.prevent="menu = 'about'"
        >
          {{ $t("menu.about") }}
        </span>
        <span
          id="menu-work"
          class="menu mr-5"
          @click.stop.prevent="menu = 'work'"
        >
          {{ $t("menu.work") }}
        </span>
        <span
          id="menu-contact"
          class="menu mr-5"
          @click.stop.prevent="menu = 'contact'"
        >
          {{ $t("menu.contact") }}
        </span>

        <!-- <span id="menu-en" class="lang mr-1" @click.stop.prevent="lang = 'en'">
          EN
        </span>
        <span class="menu mr-1">|</span>
        <span id="menu-th" class="lang mr-1" @click.stop.prevent="lang = 'th'">
          ไทย
        </span> -->
      </div>
    </v-app-bar>

    <v-main>
      <div class="menu-div" id="menu-div">
        <div class="menu-text">
          <h1 @click.stop.prevent="menu = 'home'">HOME</h1>
          <h1 @click.stop.prevent="menu = 'about'">ABOUT US</h1>
          <h1 @click.stop.prevent="menu = 'work'">WORKS</h1>
          <h1 @click.stop.prevent="menu = 'career'">CAREER</h1>
          <h1 @click.stop.prevent="menu = 'contact'">CONTACT US</h1>
        </div>

        <v-icon class="menu-close mt-2 mr-2" @click.stop.prevent="closeMenu()">
          mdi-close
        </v-icon>
      </div>
      <Home :menu="menu" />
    </v-main>
  </v-app>
</template>

<script>
import Home from "./components/Home"

export default {
  name: "App",

  components: {
    Home,
  },

  data: () => ({
    menu: "",
    lang: "",
    isMobile: false,
    showMenu: false,
    scroll: 0,
  }),
  watch: {
    menu() {
      let arr = document.getElementsByClassName("menu")
      for (let i = 0; i < arr.length; i++) {
        arr[i].classList.remove("active")
      }
      document.getElementById("menu-" + this.menu).classList.add("active")
    },
    lang() {
      // if (this.lang == "th") {
      //   document.getElementById("menu-en").classList.remove("active")
      //   document.getElementById("menu-th").classList.add("active")
      //   this.$i18n.locale = "th"
      // } else {
      //   document.getElementById("menu-en").classList.add("active")
      //   document.getElementById("menu-th").classList.remove("active")
      //   this.$i18n.locale = "en"
      // }
    },
  },
  created() {
    window.addEventListener("resize", this.detectWindowSize)
  },
  destroyed() {
    window.removeEventListener("resize", this.detectWindowSize)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
    this.isMobile = window.innerWidth < 1024 ? true : false

    if (this.isMobile)
      document.getElementById("menu-icon").classList.add("active")
    else {
      // document.getElementById("menu-home").classList.add("active")
      // document.getElementById("menu-en").classList.add("active")
    }
  },
  methods: {
    onScroll() {
      // this.windowTop = window.top.scrollY
      console.log(window.top.scrollY)
      this.scroll = window.top.scrollY
    },
    detectWindowSize() {
      this.isMobile = window.innerWidth < 1024 ? true : false
    },
    openMenu() {
      document.getElementById("app").style.overflowY = "hidden"
      document.getElementById("menu-icon").style.display = "none"
      document.getElementById("menu-div").style.display = "block"
    },
    closeMenu() {
      document.getElementById("app").style.overflowY = "auto"
      document.getElementById("menu-icon").style.display = "block"
      document.getElementById("menu-div").style.display = "none"
    },
  },
}
</script>

<style lang="scss">
html,
body,
.v-application {
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Noto Sans Thai", sans-serif;
}

.col {
  flex-basis: unset;
}
.container,
section {
  scroll-behavior: smooth;
  overflow-y: hidden;
  overflow-x: hidden;
}
// HEADER
.header {
  padding: 10px;
  margin-bottom: 20px;
  background: transparent !important;
  box-shadow: unset !important;
  position: fixed;
  height: unset !important;
  top: 0;
}
.pd-l {
  padding-left: 100px;
}
.pd-r {
  padding-right: 100px;
}
.menu {
  font-size: 18px;
}
.txt-primary {
  color: #0077ed;
}
.txt-bold {
  font-weight: bold !important;
}
.active {
  font-weight: 900;
  color: #0077ed;
}
.menu-icon {
  font-size: 48px !important;
  color: #0077ed;
}
.menu-close {
  font-size: 48px !important;
  color: #fff !important;
  position: absolute !important;
  top: 20px;
  right: 20px;
}
.menu-div {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  color: #fff;
  background-color: #0077ed;
  z-index: 100;
}
.menu-text {
  transform: translate(30%, 60%);
}

.bannerbg {
  background: url("@/assets/desktop/image-banner.png") no-repeat;
  background-size: cover;
}
.mobilebg {
  background: url("@/assets/mobile/image-banner.png") no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.footer {
  width: 100vw;
  background: #fff;
  // position: fixed;
  // bottom: 0;
  padding: 5px;
  height: 50px;
  > span {
    line-height: 35px;
    vertical-align: middle;
    margin: 0 auto;
  }
}
.inline {
  font-weight: normal;
  display: inline;
}

// HOME
.v-main {
  padding: 0 !important;
}
h1 {
  font-size: 2.5em;
}
h2 {
  font-size: 1.7em;
}
h3 {
  font-size: 1.5em;
}
h4 {
  font-size: 1.5em;
}
h5 {
  font-size: 1.2em;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h5 {
  word-break: break-word;
  word-wrap: break-word;
}

.slide-txt {
  display: inline-block;
  margin: 0 auto;
  text-align: left !important;
}
.heading {
  height: 100px;
  background-color: #c9dff4;
}
.home-div {
  // transform: translateY(25vh);
}
.about-div {
  padding: 50px 0;
  // transform: translateY(15vh);
}
.work-div {
  padding: 50px 0;
  // transform: translateY(25vh);
}
.contact-div {
  padding: 50px 100px;
  // transform: translateY(25vh);
  // height: 80vh;
  color: #fff;
}
.bg-gradirent {
  background: radial-gradient(#ffffff, #c0d9f2);
}
.body-text {
  padding: 50px 100px;
}
.body-heading {
  margin: 0 auto;
  text-align: center;
  margin: 20px 0;
  padding-top: 20px;
}
.no-padding {
  padding: 0;
  max-width: 100vw;
}
.logo {
  height: 200px;
}
.subheading {
  padding: 0 30px;
  font-size: 1.2em;
  text-align: center;
}
.bg-1 {
  background: url("@/assets/service/bg.png") no-repeat;
  background-size: cover;
}
.mobilebg-1 {
  background: url("@/assets/service/mobile-bg.png") no-repeat;
  background-size: cover;
}
.bg-2 {
  background: url("@/assets/bg-desktop.png") no-repeat;
  background-size: cover;
}
.mask1 {
  position: absolute;
  right: 0;
  top: 15%;
  img {
    max-width: 150px;
  }
}
.mask2 {
  position: absolute;
  left: 0;
  top: 58%;
  img {
    max-width: 200px;
  }
}

.carousel1 {
  padding: 50px 0;
}
.carousel2 {
  .VueCarousel {
    width: 80vw;
    margin: 50px auto;
  }
  .VueCarousel-inner {
    height: 500px !important;
  }
  .slide {
    position: relative;
    margin: 0 10px;
    max-width: 350px;
    height: 450px;
    border: 1px solid #00000010;
    background: #fff;
    box-shadow: -9px 11px 15px -2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: -9px 11px 15px -2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -9px 11px 15px -2px rgba(0, 0, 0, 0.3);
    .subtxt {
      padding: 0 10px;
    }
    span {
      word-wrap: break-word;
      word-break: break-word;
    }
  }
  .slide1 {
    background: url("@/assets/service/Insurance.png") no-repeat center;
    background-size: cover;
    height: 150px;
  }
  .slide2 {
    background: url("@/assets/service/Brokerage.png") no-repeat center;
    background-size: cover;
    height: 150px;
  }
  .slide3 {
    background: url("@/assets/service/CapitalMarket.png") no-repeat center;
    background-size: cover;
    height: 150px;
  }
  .slide4 {
    background: url("@/assets/service/PublicSector.png") no-repeat center;
    background-size: cover;
    height: 150px;
  }

  .slide:hover .overlay {
    opacity: 0.75;
  }
}
.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #0077ed;
}

.text {
  color: white;
  width: 100%;
  font-size: 60px;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  i {
    padding: 0 10px;
    font-size: 40px !important;
  }
}

.btn-career {
  margin-top: 50px;
  span {
    color: #fff;
    font-size: 24px;
    padding: 20px;
  }
}
.career-bg {
  position: absolute;
  bottom: 0;
  right: 50px;
}
.career-txt {
  width: 50%;
}
// .v-text-field__slot {
//   input,
//   label {
//     color: #fff !important;
//   }
// }
// .v-label-active {
//   color: #fff !important;
// }
// .v-select__slot {
//   input,
//   label,
//   i,
//   .v-select__selection,
//   .v-select__selection--comma {
//     color: #fff !important;
//   }
// }

// .primary--text {
//   color: #fff !important;
// }
.btn-contact {
  background: transparent !important;
  border: 1px solid #fff;
  margin-top: 50px;
  span {
    color: #fff;
    padding: 20px;
  }
}
.rowpadding {
  margin: 0 80px;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  // h1 {
  //   font-size: 50px;
  // }
  // h2 {
  //   font-size: 28px;
  // }
  // h3 {
  //   font-size: 20px;
  // }
  // h4 {
  //   font-size: 14px;
  // }
  // h5 {
  //   font-size: 14px;
  // }
  .home-div {
    // transform: translateY(20vh);
  }
  .about-div {
    // transform: translateY(15vh);
    // .subheading {
    //   padding: 0 10px;
    // }
  }
  .work-div {
    // transform: translateY(20vh);
    // height: auto;
  }
  .career-div {
    // transform: translateY(5vh);
    padding: 0 100px;
    // height: 80vh;
  }
  .contact-div {
    // transform: translateY(10vh);
    // padding: 0 100px;
    // // height: 80vh;
    // color: #fff;
  }
  .hero-banner1,
  .hero-banner2 {
    width: 80vw;
    transform: translateY(5vh);
    margin: 0 auto;
  }
  .display-2 {
    margin-left: 50px !important;
  }
  .VueCarousel-inner {
    height: 600px !important;
  }
  .slide {
    width: 90vw;
    height: 500px;
  }

  .subheading {
    padding: 0 10px;
    font-size: 1.2em;
  }
  .rowpadding {
    margin: unset;
  }
}

@media only screen and (max-width: 767px) {
  .menu-text {
    transform: translate(15%, 60%);
  }
  // h1 {
  //   font-size: 40px;
  // }
  // h2 {
  //   font-size: 24px;
  // }
  // h3 {
  //   font-size: 18px;
  // }
  // h4 {
  //   font-size: 14px;
  // }
  // h5 {
  //   font-size: 14px;
  // }
  .pd-l {
    padding-left: 20px;
  }
  .home-div {
    // transform: translateY(12vh);
  }
  .about-div {
    // transform: translateY(5vh);
    // padding-bottom: 100px;
    // .subheading {
    //   padding: 0 10px;
    // }
  }
  .work-div {
    // transform: translateY(20vh);
    // height: auto;
  }
  .career-div {
    // transform: translateY(10vh);
    // padding: 0 50px;
    // height: 80vh;
  }
  .contact-div {
    // transform: translateY(5vh);
    padding: 100px 50px;
    // height: auto;
    // color: #fff;
    // padding-bottom: 100px;
  }
  .hero-banner1 {
    width: 70vw;
    // transform: translateY(5vh);
    margin: 0 auto;
  }
  .hero-banner2 {
    width: 90vw;
    transform: translateY(5vh);
    margin: 0 auto;
  }
  .body-text {
    padding: 50px;
  }
  .display-2 {
    margin-left: 50px !important;
  }
  .career-txt {
    width: 100%;
  }
  .carousel2 {
    .VueCarousel-wrapper,
    .VueCarousel {
      width: 100vw;
    }
    .VueCarousel-inner {
      height: 600px !important;
    }
    .slide {
      width: 90vw;
      max-width: 300px;
      height: 400px;
    }

    .VueCarousel-pagination {
      margin-top: -200px;
    }
  }

  .subheading {
    padding: 0 20px;
    font-size: 1.2em;
  }
  .rowpadding {
    margin: unset;
  }

  .bg-2 {
    background: url("@/assets/bg-mobile.png") no-repeat;
    background-size: cover;
  }
}
</style>
