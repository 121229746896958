import Vue from "vue"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"

import VueI18n from "vue-i18n"
import i18n from "./i18n"
Vue.use(VueI18n)

import VueCarousel from "vue-carousel"
Vue.use(VueCarousel)

Vue.config.productionTip = false

import $ from "jquery"
window.$ = $

import moment from "moment"
window.$moment = moment
window.$moment.locale("th")

new Vue({
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app")
