<template>
  <div>
    <section ref="home" class="bg-gradirent no-padding">
      <div class="home-div mobilebg" v-if="isMobile">
        <v-row>
          <v-col cols="12">
            <v-img
              alt="Banner"
              class="pd-l"
              style="transform: translateY(2%); padding: 100px 0"
              contain
              :src="require('../assets/mobile/mobile-banner.svg')"
            />
          </v-col>
        </v-row>
      </div>
      <div class="home-div bannerbg" v-else>
        <v-row>
          <v-col cols="6">
            <v-img
              alt="Banner"
              class="pd-l"
              style="margin-top: 100px; padding: 20px; margin-left: 50px"
              contain
              :src="require('../assets/desktop/desktop-banner.svg')"
            />
          </v-col>
        </v-row>
      </div>
    </section>

    <section ref="about">
      <div class="mask1" v-if="!isMobile">
        <img src="@/assets/about/mask1.svg" />
      </div>
      <div class="about-div">
        <v-row justify="center">
          <h1 class="font-weight-bold mb-3">ABOUT US</h1>
        </v-row>
        <v-row class="body-text" justify="center">
          <div v-if="isMobile">
            <center>
              <h3>
                <span class="inline"> จากทีมงานประสบการณ์มากกว่า </span>
                <span
                  class="inline"
                  style="
                    padding: 0 10px;
                    font-size: 1.5em;
                    font-weight: bold;
                    color: #0077ed;
                  "
                >
                  20+
                </span>
                <span class="inline"> ปี สู่การให้บริการมาตรฐานระดับสากล </span>
              </h3>
            </center>
          </div>
          <div v-else>
            <center>
              <h2>
                <span class="inline"> จากทีมงานประสบการณ์มากกว่า </span>
                <span
                  class="inline"
                  style="
                    padding: 0 10px;
                    font-size: 1.5em;
                    font-weight: bold;
                    color: #0077ed;
                  "
                >
                  20+
                </span>
                <span class="inline"> ปี สู่การให้บริการมาตรฐานระดับสากล </span>
              </h2>
            </center>
          </div>
        </v-row>
        <v-row class="rowpadding">
          <v-col xs="12" sm="12" md="4" lg="4" xl="4" style="margin-top: 50px">
            <v-img
              alt="Banner"
              class="pd-l logo"
              contain
              :src="require('@/assets/about/regtech.png')"
            />
            <h2 class="body-heading">REGTECH</h2>
            <p class="subheading">
              เครื่องมือช่วยองค์กรให้ comply ตาม กฏหมาย เช่น Digital Id, eKYC,
              Data Privacy, Data Analytic, Digital Signature
              ด้วยเทคโนโลยีมาตรฐานสากล
            </p>
          </v-col>

          <v-col xs="12" sm="12" md="4" lg="4" xl="4" style="margin-top: 50px">
            <v-img
              alt="Banner"
              class="pd-l logo"
              contain
              :src="require('@/assets/about/softwaredev.png')"
            />
            <h2 class="body-heading">SOFTWARE DEVELOPMENT</h2>
            <p class="subheading">
              พลิกโฉมองค์กรเปลี่ยนผ่านสู่ยุคดิจิทัลด้วยซอฟต์แวร์อัจฉริยะ
              (Micro-frontends, Low-Code, RPA)
            </p>
          </v-col>
          <v-col xs="12" sm="12" md="4" lg="4" xl="4" style="margin-top: 50px">
            <v-img
              alt="Banner"
              class="pd-l logo"
              contain
              :src="require('@/assets/about/consult.png')"
            />
            <h2 class="body-heading">CONSULTATION</h2>
            <p class="subheading">
              ให้คำปรึกษาวางแผนและการบริหารงานด้านเทคโนโลยีสารสนเทศ
              เพื่อการเปลี่ยนผ่านองค์กรไปสู่องค์กรขับเคลื่อนด้วยดิจิทัล
            </p>
          </v-col>
        </v-row>
      </div>

      <div v-if="isMobile && !isIPad" class="carousel1">
        <center>
          <img
            src="@/assets/service/mobile/Head.svg"
            style="width: 90%; margin-left: 20px"
          />
          <br />
          <carousel
            :per-page="1"
            :mouse-drag="true"
            :loop="true"
            :navigationEnabled="true"
          >
            <slide>
              <img src="@/assets/service/mobile/ADVERTISING.svg" />
              <h3 style="color: #e6278c">ADVERTISING</h3>
              <br />
              <div class="slide-txt">
                <span> • Graphic Design </span> <br />
                <span> • Account Planning </span> <br />
                <span> • Account Management </span>
              </div>
            </slide>
            <slide>
              <img src="@/assets/service/mobile/MARKETING.svg" />
              <h3 class="mt-3" style="color: #f5a02d">MARKETING</h3>
              <br />
              <div class="slide-txt">
                <span> • Product Management </span> <br />
                <span> • Digital Marketing </span> <br />
                <span> • Market Research </span> <br />
                <span> • Brand Management </span>
              </div>
            </slide>
            <slide>
              <img src="@/assets/service/mobile/DIGITAL.svg" />
              <h3 style="color: #f3c406">DIGITAL</h3>
              <br />
              <div class="slide-txt">
                <span> • Front-end Web Development </span> <br />
                <span> • Learning Experience Design </span> <br />
                <span> • Social Media </span> <br />
                <span> • Web/UX Design </span> <br />
                <span> • Analytics </span> <br />
                <span> • Website Associate </span>
              </div>
            </slide>
            <slide>
              <img src="@/assets/service/mobile/DATA.svg" />
              <h3 style="color: #0474ec">DATA</h3>
              <br />
              <div class="slide-txt">
                <span> • Data Analytics </span> <br />
                <span> • Data Science </span>
              </div>
            </slide>
            <slide>
              <img src="@/assets/service/mobile/CONSULTING.svg" />
              <h3 class="mt-3" style="color: #0cdbb4">CONSULTING</h3>
              <br />
              <div class="slide-txt">
                <span> • Technology Planning </span> <br />
                <span> • Resource Planning </span> <br />
                <span> • Law and Regulations </span>
              </div>
            </slide>
            <slide>
              <img src="@/assets/service/mobile/FREELANCE.svg" />
              <h3 style="color: #2da406">FREELANCE</h3>
              <br />
              <div class="slide-txt">
                <span> • Designer </span> <br />
                <span> • Website Developer </span> <br />
                <span> • Project Manager </span>
              </div>
            </slide>
          </carousel>
        </center>
      </div>
      <div v-else>
        <center>
          <img
            src="@/assets/info.svg"
            style="width: 95%; padding: 50px 0; margin-bottom: 100px"
          />
        </center>
      </div>
      <div class="mask2" v-if="!isMobile">
        <img src="@/assets/about/mask2.svg" />
      </div>
    </section>

    <section
      ref="work"
      class="no-padding"
      :class="isMobile ? 'mobilebg-1' : 'bg-1'"
    >
      <div class="work-div">
        <v-row justify="center">
          <h2 v-if="isMobile" class="font-weight-bold mb-3">
            SERVICES BY SECTOR
          </h2>
          <h1 v-else class="font-weight-bold mb-3">SERVICES BY SECTOR</h1>
        </v-row>
        <v-row justify="center" class="carousel2">
          <center>
            <carousel :per-page="isMobile ? 1 : 4" :mouse-drag="true" loop>
              <slide>
                <div class="slide">
                  <div class="slide1"></div>
                  <h5 class="txt-primary mt-5">INSURANCE</h5>
                  <hr style="width: 50%; margin: 10px 0" />
                  <div class="subtxt">
                    <span class="inline">
                      สร้างเครื่องมือสนับสนุนการปรับเปลี่ยนเป็น
                    </span>
                    <span class="inline txt-primary txt-bold">
                      Digital Insurance
                    </span>
                    <span class="inline">
                      ปฎิบัติตามกฎหมายชุดดิจิทัล เช่น Digital ID, e-KYC, Digital
                      Signature, Data Privacy, Data Security, Data Analytics
                    </span>
                  </div>
                </div>
              </slide>
              <slide>
                <div class="slide">
                  <div class="slide2"></div>
                  <h5 class="txt-primary mt-5">BROKERAGE</h5>
                  <hr style="width: 50%; margin: 10px 0" />
                  <div class="subtxt">
                    <span class="inline">
                      เครื่องมือสนับสนุนการปฏิบัติตามกฎหมายดิจิทัล เช่น Digital
                      ID, e-KYC, Digital Signature, Data Privacy, Data Security,
                      Data Analytics เพื่อมุ่งสู่การเป็น
                    </span>
                    <span class="inline txt-primary txt-bold"> E-Broker </span>
                    <span class="inline"> และ </span>
                    <span class="inline txt-primary txt-bold">
                      Digital Insurance
                    </span>
                  </div>
                </div>
              </slide>
              <slide>
                <div class="slide">
                  <div class="slide3"></div>
                  <h5 class="txt-primary mt-5">CAPITAL MARKET</h5>
                  <hr style="width: 50%; margin: 10px 0" />
                  <div class="subtxt">
                    <span class="inline txt-primary txt-bold"> RegTech </span>
                    <span class="inline">
                      เครื่องมือสนับสนุนการปฏิบัติตามกฏหมายชุดดิจิทัล เช่น
                      Digital ID, e-KYC, Digital Signature, Data Privacy, Data
                      Security, Data Analytics
                    </span>
                  </div>
                </div>
              </slide>
              <slide>
                <div class="slide">
                  <div class="slide4"></div>
                  <h5 class="txt-primary mt-5">PUBLIC SECTOR</h5>
                  <hr style="width: 50%; margin: 10px 0" />
                  <div class="subtxt">
                    <span class="inline">
                      เปลี่ยนผ่านองค์กรภาครัฐเพื่อปรับเปลี่ยนสู่การเป็นองค์กรขับเคลื่อนด้วยดิจิทัล
                      :
                    </span>
                    <span class="inline txt-primary txt-bold">
                      Digital Transformation
                    </span>
                  </div>
                </div>
              </slide>
            </carousel>
          </center>
        </v-row>
      </div>
    </section>

    <section ref="contact" class="bg-2 no-padding">
      <div class="contact-div">
        <v-form ref="form" action v-model="valid" lazy-validation>
          <v-row dark justify="center">
            <v-col xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <h1
                  class="font-weight-bold mb-3"
                  :class="isMobile ? '' : 'display-2'"
                >
                  CONTACT
                </h1>
              </v-row>
              <v-row> <hr class="divider" /> </v-row>
              <v-row>
                <span class="mt-5" :style="isMobile ? '' : 'width: 80%'">
                  Online support: via Email and Chat
                </span>
                <span class="txt-bold" style="width: 80%">
                  <a
                    href="mailto:contactsales@datasafewithus.com"
                    style="color: #fff; text-decoration: none"
                  >
                    contactsales@datasafewizus.com
                  </a>
                </span>
              </v-row>
              <v-row>
                <span
                  class="mt-5"
                  :style="isMobile ? 'width: 100%' : 'width: 80%'"
                >
                  Office Hour
                </span>
                <span
                  class="txt-bold"
                  :style="isMobile ? 'width: 100%' : 'width: 80%'"
                >
                  09:00 am - 05:00 pm <br />
                  (Monday - Friday) <br />
                  <br />
                  Optional 24*7
                </span>
              </v-row>
              <v-row>
                <span
                  class="mt-5"
                  :style="isMobile ? 'width: 100%' : 'width: 80%'"
                >
                  Sales Representative
                </span>
                <span
                  class="txt-bold"
                  :style="isMobile ? 'width: 100%' : 'width: 80%'"
                >
                  +66 (0)81-702-5477
                </span>
              </v-row>
              <!-- <v-row>
                <span class="mt-5" style="width: 80%">
                  Project Coordinator
                </span>
                <span class="txt-bold" style="width: 80%">
                  +66 (0)00-000-0000
                </span>
              </v-row> -->
              <v-row>
                <p
                  class="mt-5"
                  :style="isMobile ? 'width: 100%' : 'width: 80%'"
                >
                  <v-icon color="white"> mdi-facebook </v-icon>
                  <v-icon class="ml-2" color="white"> mdi-linkedin </v-icon>
                </p>
              </v-row>
            </v-col>
            <v-col xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-text-field
                  dark
                  label="Name"
                  :rules="[(v) => !!v || 'กรุณาใส่ชื่อ']"
                  v-model="name"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  dark
                  label="E-Mail"
                  :rules="emailRules"
                  v-model="email"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  dark
                  label="Tel."
                  v-model="telno"
                  :rules="[(v) => !!v || 'กรุณาใส่เบอร์โทร']"
                  pattern="[0-9]*"
                  min="0"
                  inputmode="decimal"
                  maxlength="10"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-select
                  dark
                  :items="items"
                  label="Please Select"
                  :rules="[(v) => !!v || 'กรุณาเลือก']"
                  v-model="select"
                ></v-select>
              </v-row>
              <v-row>
                <v-textarea
                  dark
                  label="Your Message"
                  v-model="message"
                  :rules="[(v) => !!v || 'กรุณาใส่ข้อความ']"
                ></v-textarea>
              </v-row>
              <v-row class="mt-2 mb-5">
                <v-btn
                  dark
                  outlined
                  height="50"
                  class="btn-contact txt-bold"
                  @click.stop.prevent="submit()"
                >
                  SEND MESSAGE
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </section>
    <div class="footer">
      <span> <center>© Copyright 2022 Data Safe Wiz Us Co., Ltd.</center></span>
    </div>

    <v-snackbar v-model="snackbar_error" top rounded="pill" color="#ff0000">
      {{ text }}
    </v-snackbar>
    <v-snackbar v-model="snackbar_success" top rounded="pill" color="#39ab4e">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "FirstPage",
  props: ["menu"],
  data: () => ({
    items: ["สมัครงาน", "ติดต่อบริษัท", "เรื่องอื่นๆ"],
    isMobile: false,
    isIPad: false,
    valid: true,
    scroll: 0,

    name: null,
    email: null,
    telno: null,
    select: null,
    message: null,

    emailRules: [
      (v) => !!v || "กรุณากรอกอีเมล",
      (v) => !/[^a-zA-Z0-9@_. ]/.test(v) || "อีเมลไม่ถูกต้อง",
      (v) => /\S+@\S+\.\S+/.test(v) || "รูปแบบอีเมลไม่ถูกต้อง",
      (v) => !/\b \b/.test(v) || "รูปแบบอีเมลไม่ถูกต้อง",
    ],
    phoneRules: [
      (v) => (v && v.length == 10) || "หมายเลขโทรศัพท์ไม่ถูกต้อง",
      (v) => (v && v.substr(0, 1) == "0") || "หมายเลขโทรศัพท์ไม่ถูกต้อง",
      (v) =>
        (v &&
          (v.substr(1, 1) == "8" ||
            v.substr(1, 1) == "9" ||
            v.substr(1, 1) == "6")) ||
        "หมายเลขโทรศัพท์ไม่ถูกต้อง",
      (v) => !/[^0-9]/.test(v) || "หมายเลขโทรศัพท์ไม่ถูกต้อง",
    ],

    text: null,
    snackbar_error: false,
    snackbar_success: false,
  }),
  watch: {
    menu() {
      this.scrollMeTo(this.menu)
    },
  },
  created() {
    window.addEventListener("resize", this.detectWindowSize)
  },
  destroyed() {
    window.removeEventListener("resize", this.detectWindowSize)
  },
  mounted() {
    this.isMobile = window.innerWidth < 1024 ? true : false
    this.isIPad =
      window.innerWidth > 767 && window.innerWidth < 1024 ? true : false
  },
  methods: {
    detectWindowSize() {
      this.isIPad =
        window.innerWidth > 767 && window.innerWidth < 1024 ? true : false
      this.isMobile = window.innerWidth < 1024 ? true : false
    },
    scrollMeTo(refName) {
      this.page = refName.substr(refName.length - 1)

      setTimeout(() => {
        // if (this.page == "2") {
        //   window.scrollTo(
        //     0,
        //     document.body.scrollHeight || document.documentElement.scrollHeight
        //   )
        // } else {
        var element = this.$refs[refName]
        var top = element.offsetTop
        window.scrollTo(0, top)
        // }
      }, 100)
    },
    async submit() {
      var qs = require("qs")

      if (this.$refs.form.validate()) {
        const url =
          "https://docs.google.com/forms/u/0/d/e/1FAIpQLSeWEc4U5ZGRk55juUV9Bo9MOUP_7ivaKX9Eq7Su5e_pqIJvGQ/formResponse"
        const body = {
          "entry.1136003033": this.name,
          "entry.1689952893": this.email,
          "entry.128841076": this.telno,
          "entry.217474865": this.select,
          "entry.498321424": this.message,
        }

        let d = qs.stringify(body)

        try {
          this.loading = true
          await this.postData(url, d)
        } catch (error) {
          this.snackbar_error = true
          this.text = "Error Submit Data"
          this.name = null
          this.email = null
          this.select = null
          this.telno = null
          this.message = null
          if (this.$refs.form) this.$refs.form.resetValidation()
        } finally {
          this.snackbar_success = true
          this.text = "Submit Successfully"
          this.name = null
          this.email = null
          this.select = null
          this.telno = null
          this.message = null
          if (this.$refs.form) this.$refs.form.resetValidation()
        }
      }
    },
    postData(url, data) {
      return fetch(url, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        // body: qs.stringify(data),
        body: data,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.divider {
  width: 200px;
  margin: 20px 0;
  background-color: #0077ed;
  box-shadow: unset;
}
.txt-1 {
  transform: translateY(30%);
  padding: 20px;
  padding-right: 100px;
}
</style>
